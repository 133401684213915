//React
import { useEffect, useState, useRef } from "react";
//Iconos material-ui
import {
  MenuOutlined,
  Star,
  MoreVert,
} from "@material-ui/icons";

//Herramientas material
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItemIcon,
  Toolbar,
  useMediaQuery,
  Grid,
  Icon,
} from "@material-ui/core";
import GoogleIcon from '@mui/icons-material/Google';
import { ListItemButton } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ReceiptIcon from "@mui/icons-material/Receipt";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ConstructionIcon from '@mui/icons-material/Construction';
import { useTheme } from "@mui/material/styles";
import InventoryIcon from '@mui/icons-material/Inventory';
import PostAddIcon from '@mui/icons-material/PostAdd';

//Importaciones del proyecto
import "../../form_business_partner/resp.css";
import "./menu.css";
import SearchBar from "../../nav/SearchBar";
import fcimagen from "../../img/boellhoff-logo.svg";
import * as icon from "./Iconos";
import * as style from './style'
import { arrStorage } from "./data";
import { ContentPasteSharp } from "@mui/icons-material";
import "./tour.css";
import MODAL_TABLE from "../modal/modal_Table";
import { refresh_token } from "../tokens/tokenrefresh";

const AWS = require('aws-sdk');
const cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({
  region: 'us-east-1',
  credentials: {
    accessKeyId: 'AKIAWMALXHPLUSYLUUFT',
    secretAccessKey: '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',
  },
});



//Componente principal
export const MenuDrawer = (props) => {
  //Tamaños de pantalla
  const theme = useTheme();
  const screenMd = useMediaQuery(theme.breakpoints.down("md"));
  //const screenLg = useMediaQuery(theme.breakpoints.down("lg"));
  const screenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [showcontrol, setShowcontrol] = useState("block");
  const [showitem, setShowItem] = useState(style.styleInhab);
  const [estilo, setEstilo] = useState(style.styleClose);

  const [accountconfig, setAccountconfig] = useState(false);
  const [openconfigure, setOpenconfigure] = useState(false);

  const roles = props.ROLES

  const userId = props.userId

  localStorage.setItem("rolesfav", JSON.stringify(props.ROLES));
  localStorage.setItem("userIdfav", props.userId);


  const arrLocalStorage = arrStorage(roles, userId);

  //Estado para abrir las secciones
  const [states, setstates] = useState([
    { name: "ACTIVOS", state: false, },
    { name: "ADDENDA", state: false },
    { name: "BANCOS", state: false },
    { name: "COLABORADORES", state: false, },
    { name: "FINANZAS", state: false },
    { name: "REPORTES", state: false, },
    { name: "COMPRAS", state: false },
    { name: "FACTURAS", state: false },
    { name: "SOCIO DE NEGOCIOS", state: false, },
    { name: "OPERACIONES CON UNILEVER", state: false },
    { name: "INVENTARIOS", state: false },
    { name: "SOPORTE", state: false },
    { name: "HERRAMIENTAS CON GOOGLE", state: false },
    { name: "REPORTES", state: false },
    { name: "PEDIMENTOS", state: false }
  ]);

  const [favorite, setfavorite] = useState(false);
  const [viewtour, setviewtour] = useState(false);





  // const handleconfpost = async (tour) => {

  //   const arrLocalStorage = arrStorage(roles, userId);
  //   const nuevoJSON = arrLocalStorage?.map((valor) => {
  //     if (valor.forStorage === null || valor.forStorage === undefined || valor.restriccion === "none" || valor.restriccion === "DENEGADO"
  //     ) {
  //       return null; // O puedes devolver un objeto vacío {} si prefieres mantener el elemento en el arreglo pero sin datos.
  //     } else {
  //       return {
  //         ruta: valor.ruta,
  //         name: valor.name,
  //       };
  //     }
  //   }).filter(Boolean); // Filtramos para eliminar los elementos que fueron mapeados a null (o vacíos) debido a las condiciones.

  //   var data = {
  //     tour: tour,
  //     favorites: nuevoJSON,
  //   }
  //   try {
  //     var rf_token = await refresh_token();
  //     if (rf_token === true) {
  //       setTimeout(async () => {
  //         try {
  //           const d = await post_config_user(data);
  //           console.log(d);
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       }, 1000);
  //     } else { }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }



  //handle mouse over
  const handleMouseOver = () => {
    if (open || screenMd) return;
    setShowItem(style.styleHab);
    setEstilo(style.styleOpen);
  };

  //handle mouseout
  const handleMouseOut = () => {
    if (open || screenMd) return;
    setShowItem(style.styleInhab);
    setEstilo(style.styleClose);
  };

  //Función cuando uno abre los demàs cierran
  const handleStateChange = (indice) => {
    const updatedStates = [...states];

    updatedStates.map((valor, index) => {

      if (indice === index) {
        if (updatedStates[index].state === true) {
          updatedStates[index].state = false;
          return valor;
        }
        updatedStates[index].state = true;
      }

      else {
        updatedStates[index].state = false;
      }
      return valor;
    })
    setstates(updatedStates);
  }




  //useEffet for when openState changed
  useEffect(() => {
    if (open) {
      setShowItem(style.styleHab);
      props.onSideBarStatic();
      setEstilo(style.styleOpen);
      setShowcontrol("block");
    } else {
      setShowItem(style.styleInhab);
      setEstilo(style.styleClose);

      if (screenMd) {
        setShowcontrol("none");
        props.onSideBarHide();
      } else {
        props.onSideBarNoStatic();
        setShowcontrol("block");
      }
    }
  }, [open]);

  const avatar = props.userEmail ? props.userEmail.charAt(0).toUpperCase() : "";
  const iconAddenda = icon.iconoAddendas("medium");


  function checkLocalStorage(itemName) {
    return localStorage.getItem(itemName) !== null ? true : false;
  }

  function handleremoveItem(itemName, itemValue) {
    console.log("Valor", itemName)
    if (localStorage.getItem(itemName) !== null) {
      localStorage.removeItem(itemName);
      setfavorite(false);

    } else {
      localStorage.setItem(itemName, itemValue);
      setfavorite(true)
    }

    return favorite;
  }



  //When the screen size changes.
  window.onresize = () => {
    if (!screenMd) {
      setShowcontrol("block");
      open ? props.onSideBarStatic() : props.onSideBarNoStatic();
    } else {
      props.onSideBarHide();
      setShowcontrol("none");
    }
  };


  //tour
  const [stepIndex, setStepIndex] = useState(0);



  const handleClickIcon = () => {
    setStepIndex(stepIndex + 1);
  };

  const [modal, setmodal] = useState(false);
  const handleCerrarSesion = async () => {


    setmodal(true);
    // await handleconfpost("false");
    // await handless();

    setTimeout(() => {
      props.closeSession();
      setmodal(false);
    }, 5000);
  };


  // const handless = async () => {

  //   const date = Date();
  //   //revocar();

  //   var data = {
  //     signup: date,
  //   }
  //   try {
  //     var rf_token = await refresh_token();
  //     if (rf_token === true) {
  //       setTimeout(async () => {
  //         try {
  //           const d = await post_config_signin(data);
  //           console.log(d);
  //         } catch (err) {
  //           console.log(err);
  //         }
  //       }, 1000);
  //     } else { }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }






  const favoritosRef = useRef(null);

  useEffect(() => {
    if (favoritosRef.current) {
      const rect = favoritosRef.current.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;

      console.log("Posición top: " + topPosition + "px");
      console.log("Posición left: " + leftPosition + "px");
    }
  }, []);

  return (
    <div>

      {
        modal === true ?
          <MODAL_TABLE
            open={true}
            message={"Cerrando Sesión"}
            modalGeneral={true}
            modalT={false}
          ></MODAL_TABLE>
          : <></>
      }
      {/* Para mantener estilos consistentes */}
      <CssBaseline />


      {/* Barra de navegación */}
      <AppBar id="appbar" style={{ zIndex: 205 }} className="appbar" position="fixed">
        {/* Herramientas de la barra de navegación */}
        <Toolbar
          style={style.styleToolbar}
        >
          <div style={style.styleImg}>
            <a href="https://bf.herramientasinformaticas.com">
              <img
                style={{
                  position: "relative",
                  left: "5px",
                  top: "2px",
                  width: estilo === style.styleOpen ? "135px" : "65px",
                  height: estilo === style.styleOpen ? "40px" : "30px",
                }}
                src={fcimagen}
                alt="logo"
              />
            </a>
          </div>

          <Icon
            id="icono-menu-od2"
            className="icono-menu-od2"
            onClick={() => {
              handleClickIcon();
              if (accountconfig) {
                setAccountconfig(false);
                setOpen(true);
                return;
              }

              setOpen(!open)

            }}
            style={{ ...style.styleIconMenu, left: estilo === style.styleOpen ? "200px" : "80px", }}
          >
            <MenuOutlined className="iconMenu" />
          </Icon>

          <div
            id="tour-input-od2"
            className="searchbar tour-input-od2"
            style={{
              ...style.styleSearchBar,
              display: screenSmall ? "none" : "block",
            }}
          >
            <SearchBar
              handleClickIcon={handleClickIcon}
              placeholder="Buscar ..."
              data={arrLocalStorage}
            />
          </div>

          <div
            onClick={() => {
              if (open) {
                setOpen(false)
                setAccountconfig(true);
                return;
              }

              setAccountconfig(!accountconfig)

            }
            }
            id="for-avatar"
          >
            <Avatar className="avatar" alt={props.userEmail}>
              {avatar} <span title={screenMd ? props.userEmail : ""}> </span>
            </Avatar>
            <MoreVert fontSize="large" className="more" />
          </div>

          <Grid style={{ ...style.styleConfig, display: accountconfig ? "block" : "none", borderRadius: "0 0 4px 4px" }}>
            <ListItemButton>{props.userEmail}</ListItemButton>

            <ListItemButton
              onClick={() => window.location = "/profile"}
              style={{ display: "block" }}
            >
              <span>MI PERFIL</span>

            </ListItemButton>
            <ListItemButton
              onClick={() => setOpenconfigure(!openconfigure)}
              style={{ display: accountconfig && props.ROLE_CONFIGURATION !== "none" ? "block" : "none" }}
            >
              <span>CONFIGURACI&Oacute;N</span>
              <ul style={{ display: openconfigure ? "block" : "none", }}>
                <li style={{ ...style.styleConfigSections, display: "" }} onClick={() => window.location = "/Tablewarehouse"}> <a href="/TableWarehouse"> ALMAC&Eacute;N </a> </li>
                <li style={{ ...style.styleConfigSections, display: "" }} onClick={() => window.location = "/edit_company"}> <a href="/edit_company"> EMPRESA </a> </li>
                <li style={{ ...style.styleConfigSections, display: props.ROLE_ADMIN }} onClick={() => window.location = "/consult_roles"}> <a href="/consult_roles"> ROLES </a> </li>
                <li style={{ ...style.styleConfigSections, display: "" }} onClick={() => window.location = "/Consult_route"}> <a href="/Consult_route"> RUTAS</a> </li>
                <li style={{ ...style.styleConfigSections, display: "block" }} onClick={() => window.location = "/consult_users"}> <a href="/consult_users"> USUARIOS </a> </li>
              </ul>
            </ListItemButton>

            <ListItemButton
              onClick={handleCerrarSesion}
              style={{ display: accountconfig ? "block" : "none" }}
            >
              <span>CERRAR SESI&Oacute;N</span>

            </ListItemButton>


          </Grid>
        </Toolbar>
      </AppBar>

      <Drawer
        className="favoritos"
        open
        variant="permanent"
        PaperProps={{
          style: style.drawerFavorite
        }}
      >
        <span
          id="scrollbar"
          style={style.styleSpan}
        >
          {
            arrLocalStorage.map((valor) => {

              if (
                valor.forStorage === null ||
                valor.forStorage === undefined ||
                valor.restriccion === "none" ||
                valor.restriccion === "DENEGADO"
              )
                return "";
              return (
                <a
                  key={valor.name}
                  className="favOD2"
                  ref={favoritosRef}

                  href={valor.ruta}
                  onMouseOver={() => {
                    setHoveredElement(valor.name)
                  }}
                  onMouseOut={() => {
                    setHoveredElement(null)
                  }}
                  style={{
                    top: "0",
                    margin: "0px 2px 0px 2px",
                    padding: "5px 3px 6px 3px",

                    border: hoveredElement === valor.name ? "1px solid white" : "none",
                    borderRadius: "0px",
                  }}

                  onClick={() => { console.log(valor.forStorage) }}
                >
                  <span
                    className="fav-od2-user"
                    id="fav-od2-user"
                    style={{ color: "#ffff", fontWeight: "unset" }}>
                    {valor.icono} {valor.name}{" "}
                  </span>
                </a>
              );
            })}
        </span>
      </Drawer>

      <Box className="sidebar" display={showcontrol} id="sidebar">

        <Drawer
          open
          PaperProps={{ style: estilo }}
          variant="permanent"
          onMouseOver={handleMouseOver}
          onMouseOutCapture={handleMouseOut}
        >
          <List className="list-tour" id="list-tour">

            {/* ACTIVOS   */}
            <Grid
              style={{
                display: props.ROLE_ASSETS === "none" && props.ROLES.ROLE_CABINETS === "none" ? "none" : "",
                color: states[0].state === true ? "white" : "#F6F6EC",
                fontStyle: states[0].state === true ? "oblique" : "normal",
                textShadow: states[0].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[0].state === true ? "bold" : "normal" }}
                onClick={() => handleStateChange(0)}
                className="itemsDrawer">

                <MonetizationOnIcon
                  style={{
                    ...style.iconStyle,
                    border: states[0].state === true ? "1px solid white" : "none",
                  }}
                  sx={{ fontSize: 30 }} />

                <span style={showitem} >
                  ACTIVOS {icon.desplegar(states[0].state)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[0].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li style={{ display: props.ROLES.ROLE_CABINETS === "none" ? "none" : "" }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}consult_frezzers`, "Congeladores")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_frezzers`)
                          ? style.selectedStar
                          : style.unselectedStar
                      } />

                  </Icon>
                  <a className="encabezadoA nColor" href="/consult_frezzers">
                    CONGELADORES
                  </a>
                </li>
                {/* <li style={{ display: props.ROLE_ASSETS === "none" ? "none" : "" }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={(event) => {
                      handleremoveItem(`${props.userId}consult_brands`, "Marcas y Modelos") ?
                        event.currentTarget.style.color = "yellow" :
                        event.currentTarget.style.color = "white"
                    }}
                  >
                    <Star style={
                      checkLocalStorage(`${props.userId}consult_brands`)
                        ? style.selectedStar
                        : style.unselectedStar}
                    />

                  </Icon>
                  <a className="encabezadoA nColor" href="/consult_brands">
                    MARCAS Y MODELOS
                  </a>
                </li> */}
                <li style={{ display: props.ROLE_ASSETS === "none" ? "none" : "" }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}consult_vehicle`, "Vehiculos")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_vehicle`)
                          ? style.selectedStar
                          : style.unselectedStar
                      } />

                  </Icon>
                  <a className="encabezadoA nColor" href="/consult_vehicle">
                    VEHÍCULOS
                  </a>
                </li>

              </ul>
            </Grid>

            <Divider />

            {/* Addenda*/}
            <Grid
              style={{
                display: props.ROLE_ADDENDAS,
                justifyContent: "center",
                color: "white",
                fontStyle: states[1].state === true ? "oblique" : "normal",
                textShadow: states[1].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton className="itemsDrawer">
                <ListItemIcon
                  onClick={() => handleStateChange(1)}
                  style={style.iconStyle}
                >
                  {iconAddenda}
                </ListItemIcon>
                <a
                  href="/Addenda"
                  style={showitem}
                >
                  ADDENDA {icon.desplegar(states[1].state)}
                </a>
                <Icon
                  style={style.styleIconStar}
                  onClick={() => handleremoveItem(`${props.userId}Addenda`, "Addenda")}
                >
                  <Star
                    style={
                      checkLocalStorage(`${props.userId}Addenda`)
                        ? style.selectedStar
                        : style.unselectedStar
                    }
                  />
                </Icon>
              </ListItemButton>
            </Grid>

            <Divider />

            {/* BANCOS   */}
            <Grid
              style={{
                display: props.ROLE_BANK === " " || props.ROLE_SALES === " " ? "block" : "none",
                color: "white",
                fontStyle: states[2].state === true ? "oblique" : "normal",
                textShadow: states[2].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[2].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(2)} className="itemsDrawer">
                <AccountBalanceIcon
                  style={{
                    ...style.iconStyle,
                    border: states[2].state === true ? "2px solid white" : "none",
                  }}
                  sx={{ fontSize: 30 }} />

                <span style={showitem} >
                  BANCOS {icon.desplegar(states[2].state)}
                </span>
              </ListItemButton>

              <Divider flexItem />

              <ul className="lista"
                style={{
                  display:
                    states[2].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                      ? "block"
                      : "none",
                }}
              >
                <li style={{ display: props.ROLE_BANK }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}cashclosing`, "Corte de Caja")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}cashclosing`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/cashclosing">
                    CORTE DE CAJA
                  </a>
                </li>
                <li style={{ display: props.ROLE_BANK }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}DEBTS`, "DEBTS")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}DEBTS`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/DEBTS">
                    DEUDAS
                  </a>
                </li>
                <li style={{ display: props.ROLE_BANK }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}PAID`, "PAID")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}PAID`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/PAID">
                    PAGOS
                  </a>
                </li>
                <li style={{ display: props.ROLE_BANK }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}old_balances`, "old_balances")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}old_balances`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/old_balances">
                    ANTIGUEDAD DE SALDOS
                  </a>
                </li>


              </ul>
            </Grid>

            <Divider />

            {/* COLABORADORES */}
            <Grid
              style={{
                display: props.ROLE_HUMAN_RESOURCES,
                color: "white",
                fontStyle: states[3].state === true ? "oblique" : "normal",
                textShadow: states[3].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[3].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(3)} className="itemsDrawer">
                <EmojiPeopleIcon
                  style={{
                    ...style.iconStyle,
                    border: states[3].state === true ? "2px solid white" : "none",
                  }}
                  sx={{ fontSize: 30 }} />

                <span
                  style={showitem}

                >
                  COLABORADORES {icon.desplegar(states[3].state === true)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[3].state === true &&
                    estilo !== style.styleClose &&
                    showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_employees`,
                        "Consultar Empleados"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_employees`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/consult_employees">
                    CONSULTAR EMPLEADOS
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_driver`,
                        "Consultar Conductores"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_driver`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/consult_driver">
                    CONSULTAR CONDUCTORES
                  </a>
                </li>

                {/* <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}daily_staff`,
                        "Consultar Conductores"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}daily_staff`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/daily_staff">
                    ASIGNACIÓN DE PERSONAL
                  </a>
                </li> */}

              </ul>
            </Grid>

            <Divider />

            {/* FINANZAS   */}
            <Grid
              style={{
                display: props.ROLE_FINANCE,
                color: "white",
                fontStyle: states[4].state === true ? "oblique" : "normal",
                textShadow: states[4].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[4].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(4)} className="itemsDrawer">
                <PriceChangeIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle, border: states[4].state === true ? "2px solid white" : "none",
                  }} />
                <span style={showitem}>
                  FINANZAS {icon.desplegar(states[4].state)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[4].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_accounting_account`,
                        "Cuenatas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_accounting_account`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/consult_accounting_account"
                  >
                    CUENTAS CONTABLES
                  </a>
                </li>
              </ul>
            </Grid>

            <Divider />

            {/* INVENTARIOS   */}
            <Grid
              style={{
                display: props.ROLE_INVENTORY,
                color: "white",
                fontStyle: states[4].state === true ? "oblique" : "normal",
                textShadow: states[4].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[10].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(10)} className="itemsDrawer">
                <InventoryIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle, border: states[10].state === true ? "2px solid white" : "none",
                  }} />
                <span style={showitem}>
                  INVENTARIOS {icon.desplegar(states[10].state)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[10].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >

                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}products_line`,
                        "Cuenatas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}products_line`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/products_line"
                  >
                    LINEA DE PRODUCTOS
                  </a>
                </li>

                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}products`,
                        "Cuenatas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}products`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/products"
                  >
                    PRODUCTOS
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}price_list`,
                        "Lista de precios"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}price_list`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/price_list"
                  >
                    LISTA DE PRECIOS
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}stock`,
                        "Cuenatas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}stock`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/stock"
                  >
                    ALMACEN DE PRODUCTOS
                  </a>
                </li>


                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}movements`,
                        "Cuenatas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}movements`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/movements"
                  >
                    MOVIMIENTO DE PRODUCTOS
                  </a>
                </li>

                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}product_load`,
                        "Cuenatas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}product_load`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/product_load"
                  >
                    CARGA DE PRODUCTOS
                  </a>
                </li>



              </ul>
            </Grid>

            <Divider />


            {/* REPORTES   */}
            <Grid
              style={{
                display: props.ROLE_SALES,
                color: "white",
                fontStyle: states[5].state === true ? "oblique" : "normal",
                textShadow: states[5].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[5].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(5)} className="itemsDrawer">
                <ContentPasteSharp
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[5].state === true ? "2px solid white" : "none",
                  }}
                />
                <span style={showitem} >
                  REPORTES {icon.desplegar(states[5].state)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[5].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      console.log(`${props.userId}NewDashboard`)
                      handleremoveItem(`${props.userId}NewDashboard`, "Cuenatas Contables")
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}NewDashboard`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/NewDashboard">
                    DETALLES DE VENTAS
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}rutas`, "Cuenatas Contables")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}rutas`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/rutas">
                    MAPAS DE RUTAS
                  </a>
                </li>



                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}report_sales`,
                        "Reporte de ventas "
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}report_sales`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/report_sales"
                  >
                    REPORTE DE VENTAS
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_route_sales_report`,
                        "Reporte de ventas por ruta"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_route_sales_report`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/consult_route_sales_report"
                  >
                    REPORTE DE VENTAS POR RUTA
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}Consult_report_sale_real`,
                        "Cuentas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}Consult_report_sale_real`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/Consult_report_sale_real"
                  >
                    REPORTE DE VENTAS EN TIEMPO REAL
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_report_sumary`,
                        "Cuentas Contables"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_report_sumary`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/consult_report_sumary"
                  >
                    REPORTE SUMARIA ANUAL
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_sumary_weektly`,
                        "Sumaria Semanal"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_sumary_weektly`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/consult_sumary_weektly"
                  >
                    REPORTE SUMARIA SEMANAL
                  </a>
                </li>






              </ul>
            </Grid>

            <Divider />

            {/* COMPRAS   */}
            <Grid
              style={{
                display: props.ROLE_BUYS !== "none" || props.ROLE_EXPENSE !== "none" ? "block" : "none",
                color: "white",
                fontStyle: states[6].state === true ? "oblique" : "normal",
                textShadow: states[6].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[6].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(6)} className="itemsDrawer">
                <ShoppingCartIcon
                  style={{
                    ...style.iconStyle,
                    border: states[6].state === true ? "2px solid white" : "none",
                    color: "white",
                  }}
                  sx={{ fontSize: 30 }} />

                <span style={showitem} >
                  COMPRAS {icon.desplegar(states[6].state)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[6].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li style={{ display: props.ROLE_BUYS, }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}SUPPLIER_INVOICE`,
                        "Consultar Documentos Envíados"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}SUPPLIER_INVOICE`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/SUPPLIER_INVOICE"
                  >
                    CONSULTAR DOCUMENTOS ENVÍADOS
                  </a>
                </li>
                <li style={{ display: props.ROLE_BUYS }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}validador`, "Envío de Documentos")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}validador`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/validador">
                    ENVÍO DE DOCUMENTOS
                  </a>
                </li>
                <li style={{ display: props.ROLE_BUYS }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}supplier`, "Envío de Documentos")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}supplier`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a className="encabezadoA nColor" href="/supplier">
                    CONSULTAR PROVEEDORES
                  </a>
                </li>





                <li style={{ display: props.ROLE_EXPENSE_ADMINISTRATIVOS }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_expense_concept`,
                        "Catálogo de gastos"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_expense_concept`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a //
                    className="encabezadoA nColor"
                    href="/consult_expense_concept"
                  >
                    CATALOGO DE GASTOS
                  </a>
                </li>

                <li style={{ display: props.ROLE_EXPENSE_ADMINISTRATIVOS }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_rules_sending_expenses`,
                        "Regla de gastos"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_rules_sending_expenses`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/consult_rules_sending_expenses"
                  >
                    REGLA DE GASTOS
                  </a>
                </li>

                <li style={{ display: props.ROLE_EXPENSE }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}expenses`, "Gastos no Deducibles")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}expenses`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/expenses">GASTOS NO DEDUCIBLES</a>
                </li>

                <li style={{ display: props.ROLE_EXPENSE_EXTRANJERO }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}expensesExtranjero`,
                        "Gastos del extranjero"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}expensesExtranjero`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/expensesExtranjero"> GASTOS DEL EXTRANJERO</a>
                </li>

                <li style={{ display: props.ROLE_EXPENSE_ADMINISTRATIVOS }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}expensesReport`, "Reporte de Gastos")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}expensesReport`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/expensesReport">REPORTE DE GASTOS</a>
                </li>

                <li style={{ display: props.ROLE_EXPENSE_ADMINISTRATIVOS }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}expensesGrup`,
                        "Reporte de Gastos Agrupados"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}expensesGrup`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/expensesGrup">REPORTE DE GASTOS AGRUPADOS</a>
                </li>

                <li style={{ display: props.ROLE_POLICY }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => handleremoveItem(`${props.userId}policy`, "Polizas")}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}policy`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/policy"> POLIZAS</a>
                </li>
              </ul>
            </Grid>

            <Divider />


            {/* FACTURAS*/}
            <Grid
              style={{
                display: props.ROLE_INVOICING !== "none" || props.ROLE_CARTA_PORTE !== "none" || props.ROLE_COMPLEMENTO_PAGO !== "none" ? "block" : "none",
                left: "0%",
                right: "0%",
                fontStyle: states[7].state === true ? "oblique" : "normal",
                textShadow: states[7].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[7].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(7)} className="itemsDrawer">
                <ReceiptIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[7].state === true ? "2px solid white" : "none",
                    color: "white",
                  }} />

                <span style={showitem}>
                  FACTURAS {icon.desplegar(states[7].state)}
                </span>
              </ListItemButton>

              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[7].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li style={{ display: props.ROLE_INVOICING }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}consult_concept`, "Consultar Conceptos")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_concept`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_concept" className="encabezadoA nColor">
                    CONSULTAR CONCEPTOS
                  </a>
                </li>

                <li style={{ display: props.ROLE_INVOICING }} >
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}/invoice_v2`, "Consultar Facturas")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}/invoice_v2`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/invoice_v2" className="encabezadoA nColor">
                    CONSULTAR FACTURAS
                  </a>
                </li>

                <li style={{ display: props.ROLE_INVOICING }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}FacturasPPD`, "Consultar Facturas PPD")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}FacturasPPD`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/FacturasPPD" className="encabezadoA nColor">
                    CONSULTAR FACTURAS PPD
                  </a>
                </li>

                <li style={{ display: props.ROLE_INVOICING }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}consult_taxes`, "Consultar Impuestos")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_taxes`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_taxes" className="encabezadoA nColor">
                    CONSULTAR IMPUESTOS
                  </a>
                </li>

                <li style={{ display: props.ROLE_NOMINA }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}nomina`, "Consultar Nomina")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}nomina`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/nomina" className="encabezadoA nColor">
                    CONSULTAR NOMINA
                  </a>
                </li>

                <li style={{ display: props.ROLE_CARTA_PORTE }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}cartaporte`, "Realizar Carta Porte")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}cartaporte`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/cartaporte" className="encabezadoA nColor">
                    REALIZAR CARTA PORTE
                  </a>
                </li>

                <li style={{ display: props.ROLE_COMPLEMENTO_PAGO }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}pago`, "Realizar Complemento de Pago")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}pago`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/pago" className="encabezadoA nColor">
                    REALIZAR COMPLEMENTO DE PAGO
                  </a>
                </li>

                <li style={{ display: props.ROLE_INVOICING }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}GenerarFactura`, "Realizar Factura")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}GenerarFactura`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/GenerarFactura" className="encabezadoA nColor">
                    REALIZAR FACTURA
                  </a>
                </li>
                <li style={{ display: props.ROLE_INVOICING }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(`${props.userId}consult_payment_report`, "Reporte Pagos")
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_payment_report`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_payment_report" className="encabezadoA nColor">
                    REPORTE DE COMPLEMENTOS DE PAGO
                  </a>
                </li>
              </ul>
            </Grid>

            <Divider />

            {/*SOCIO DE NEGOCIOS */}

            <Grid
              style={{
                display: props.ROLE_BUSINESS_PARTNER,
                color: "white",
                left: "0%",
                right: "0%",
                fontStyle: states[8].state === true ? "oblique" : "normal",
                textShadow: states[8].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[8].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(8)} className="itemsDrawer">
                <GroupAddIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[8].state === true ? "2px solid white" : "none",
                    color: "white",
                  }} />

                <span style={showitem}>
                  <span> SOCIO DE NEGOCIOS {icon.desplegar(states[8].state)}</span>
                </span>
              </ListItemButton>

              <Divider flexItem />
              <ul className="lista"
                style={{ display: states[8].state === true && showitem !== style.styleInhab ? "block" : "none" }}>
                <li style={{ display: props.ROLE_BUSINESS_PARTNER_DISCOUNT }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      handleremoveItem(
                        `${props.userId}consult_discounts`,
                        "Consultar Descuentos"
                      );
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_discounts`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_discounts" className="encabezadoA nColor">
                    CONSULTAR DESCUENTOS
                  </a>
                </li>
                <li style={{ display: props.ROLE_BUSINESS_PARTNER_PROMOTIONS }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      handleremoveItem(
                        `${props.userId}consult_promotions`,
                        "Consultar Promociones"
                      );
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_promotions`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_promotions" className="encabezadoA nColor">
                    CONSULTAR PROMOCIONES
                  </a>
                </li>

                <li style={{ display: props.ROLE_BUSINESS_PARTNER_POINT_OF_SALE }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      handleremoveItem(
                        `${props.userId}consult_point_of_sale`,
                        "Consultar Puntos de Venta"
                      );
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_point_of_sale`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_point_of_sale" className="encabezadoA nColor">
                    CONSULTAR PUNTOS DE VENTA
                  </a>
                </li>
                <li style={{ display: props.ROLE_BUSINESS_PARTNER_SECTOR }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      handleremoveItem(
                        `${props.userId}consult_sectors`,
                        "Consultar Sectores"
                      );
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_sectors`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_sectors" className="encabezadoA nColor">
                    CONSULTAR SECTORES
                  </a>
                </li>

                <li style={{ display: props.ROLE_BUSINESS_PARTNER_BUSINESS_PARTNER }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      handleremoveItem(
                        `${props.userId}bussines_partnert_v2`,
                        "Consultar Socio de Negocios"
                      );
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}bussines_partnert_v2`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/bussines_partnert_v2" className="encabezadoA nColor">
                    CONSULTAR SOCIO DE NEGOCIOS
                  </a>
                </li>

                <li style={{ display: props.ROLE_BUSINESS_PARTNER_RECOVER }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      handleremoveItem(
                        `${props.userId}consult_recover_partner`,
                        "Recuperar Socio de Negocios"
                      );
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_recover_partner`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/consult_recover_partner" className="encabezadoA nColor">
                    RECUPERAR SOCIO DE NEGOCIOS
                  </a>
                </li>
                <li style={{ display: props.ROLE_BUSINESS_PARTNER_GENERAL_REGISTRATION }}>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() => {
                      handleremoveItem(
                        `${props.userId}register_general`,
                        "Registro General y Punto de Venta"
                      );
                    }}
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}register_general`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a href="/register_general" className="encabezadoA nColor">
                    REGISTRO GENERAL
                  </a>
                </li>
              </ul>
            </Grid>

            <Divider />

            {/* OPERACIONES CON UNILEVER   */}
            <Grid
              style={{
                display: props.ROLE_UNILEVER,
                color: "white",
                fontStyle: states[9].state === true ? "oblique" : "normal",
                textShadow: states[9].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[9].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(9)} className="itemsDrawer">
                <DragIndicatorIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[9].state === true ? "2px solid white" : "none",
                    color: "white",
                  }} />

                <span style={showitem} >
                  OPERACIONES CON UNILEVER {icon.desplegar(states[9].state === true)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[9].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}consult_report_bonuses`,
                        "Reporte de bonificaiones"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}consult_report_bonuses`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/consult_report_bonuses"
                  >
                    REPORTE DE BONIFICACIONES
                  </a>
                </li>
              </ul>
            </Grid>

            <Divider />
            {/* sOPORTE */}
            <Grid
              id="desplegar" className="desplegar"
              onClick={handleClickIcon}
              style={{
                color: "white",
                fontStyle: states[11].state === true ? "oblique" : "normal",
                textShadow: states[11].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[11].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(11)} className="itemsDrawer">
                <ConstructionIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[11].state === true ? "2px solid white" : "none",
                    color: "white",
                  }} />

                <span style={showitem} >
                  SOPORTE {icon.desplegar(states[11].state === true)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[11].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}USER_MANUAL`,
                        "MANUAL"
                      )
                    }
                  >
                    <Star
                      id="star-tour"
                      className="star-tour"
                      style={
                        checkLocalStorage(`${props.userId}USER_MANUAL`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/USER_MANUAL"
                  >
                    MANUAL DE USUARIO
                  </a>
                </li>
                {/* <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}chat_smit`,
                        "chat_smit"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}chat_smit`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/chat_smit"
                  >
                    CHAT SMIT
                  </a>
                </li> */}
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}help_center_v2`,
                        "help_center"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}help_center_v2`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/help_center_v2"
                  >
                    CENTRO DE AYUDA
                  </a>
                </li>


              </ul>
            </Grid>

            <Divider />
            <Grid
              id="desplegar" className="desplegar"
              onClick={handleClickIcon}
              style={{
                color: "white",
                fontStyle: states[14].state === true ? "oblique" : "normal",
                textShadow: states[14].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[12].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(14)} className="itemsDrawer">
                <PostAddIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[14].state === true ? "2px solid white" : "none",
                    color: "white",
                  }} />

                <span style={showitem} >
                  PEDIMENTOS {icon.desplegar(states[14].state === true)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[14].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}petitions`,
                        "Pedimentos"
                      )
                    }
                  >
                    <Star
                      id="star-tour"
                      className="star-tour"
                      style={
                        checkLocalStorage(`${props.userId}petitions`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/FileUploader"
                  >
                    SUBIR FACTURAS Y PEDIMENTOS
                  </a>
                </li>

                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}petitions`,
                        "Pedimentos"
                      )
                    }
                  >
                    <Star
                      id="star-tour"
                      className="star-tour"
                      style={
                        checkLocalStorage(`${props.userId}petitions`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/INVOICE_OR_PETITIONS"
                  >
                    CONSULTAR FACTURAS Y PEDIMENTOS
                  </a>
                </li>

                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}petitions`,
                        "Pedimentos"
                      )
                    }
                  >
                    <Star
                      id="star-tour"
                      className="star-tour"
                      style={
                        checkLocalStorage(`${props.userId}petitions`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/petitions"
                  >
                    PEDIMENTOS
                  </a>
                </li>



              </ul>
            </Grid>

            <Divider />

            {/* <Divider />
            <Grid
              style={{
                color: "white",
                fontStyle: states[12].state === true ? "oblique" : "normal",
                textShadow: states[12].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[12].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(12)} className="itemsDrawer">
                <GoogleIcon
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[12].state === true ? "2px solid white" : "none",
                    color: "white",
                  }} />

                <span style={showitem} >
                  HERRAMIENTAS CON GOOGLE{icon.desplegar(states[12].state === true)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[12].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}drive_to_drive`,
                        "Drive a Drive"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}drive_to_drive`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/drive_to_drive"
                  >
                    Drive a Drive
                  </a>
                </li>
              </ul>
            </Grid>
            <Divider /> */}
            {/* portal de pedidos */}
            <Divider />
            <Grid
              style={{
                color: "white",
                fontStyle: states[13].state === true ? "oblique" : "normal",
                textShadow: states[13].state === true ? "1px 1px 1px black" : "none"
              }}
              container
            >
              <ListItemButton style={{ fontWeight: states[13].state === true ? "bold" : "normal", }} onClick={() => handleStateChange(13)} className="itemsDrawer">
                <ContentPasteSharp
                  sx={{ fontSize: 30 }}
                  style={{
                    ...style.iconStyle,
                    border: states[12].state === true ? "2px solid white" : "none",
                    color: "white",
                  }} />

                <span style={showitem} >
                  REPORTES{icon.desplegar(states[13].state === true)}
                </span>
              </ListItemButton>
              <Divider flexItem />

              <ul className="lista"
                style={{
                  display: states[13].state === true && estilo !== style.styleClose && showitem !== style.styleInhab
                    ? "block"
                    : "none",
                }}
              >
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}factor`,
                        "Factor de Importación"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}factor`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/factor"
                  >
                    FACTOR DE IMPORTACIÓN
                  </a>
                </li>
                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}semaphore`,
                        "Semáforo"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}semaphore`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/semaphore"
                  >
                    SEMÁFORO
                  </a>
                </li>

                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}FORECAST`,
                        "FORECAST"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}FORECAST`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/FORECAST"
                  >
                    CARGAR ARCHIVOS FORECAST
                  </a>
                </li>

                <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}FORECAST_TABLE`,
                        "FORECAST_TABLE"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}FORECAST_TABLE`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/FORECAST_TABLE"
                  >
                    FORECAST BRP
                  </a>
                </li>

                {/* <li>
                  <Icon
                    style={style.styleIconStar}
                    onClick={() =>
                      handleremoveItem(
                        `${props.userId}DEBETS_PAYMENTS`,
                        "DEBETS_PAYMENTS"
                      )
                    }
                  >
                    <Star
                      style={
                        checkLocalStorage(`${props.userId}DEBETS_PAYMENTS`)
                          ? style.selectedStar
                          : style.unselectedStar
                      }
                    />
                  </Icon>
                  <a
                    className="encabezadoA nColor"
                    href="/DEBETS_PAYMENTS"
                  >
                    REPORTE DE PAGOS Y DEUDAS (DEMO)
                  </a>
                </li> */}

              </ul>
            </Grid>
            <Divider />

          </List>
        </Drawer>
      </Box>
    </div>
  );
};
